.link {
  padding-top: 15px;
  padding-left: 25px;
  font-size: 13px;
  cursor:pointer;
  color:rgb(119, 149, 247);
}

.link:hover {
  color:rgb(152, 176, 255);
}
