.container {
  position: relative;
  top: 0px;
  left: -20px;
  height: 35px;
  width: 340px;
  border-radius: 0px 5px 5px 0px;
  background-color: #e0e0e0;
  box-shadow: 2px 4px 6px #9b9b9b;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  padding-left: 10px;
}

.searchField {
  color: grey;
  font-size: 11px;
  background-color: white;
  padding-left: 8px;
  border: none;
  border-radius: 5px;
  height: 23px;
  width: 130px;
}
