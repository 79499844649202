.container {
  position: fixed;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 10000;
  pointer-events: none;
}

@media print {
  .container {
    display: none;
  }
}
