.container {
  position: relative;
}

.error {
  position: absolute;
  bottom: 13px;
  left: 110px;
  max-height: 40px;
}
