.content {
  text-align: center;
  padding-top: 20px;
}

.message {
  color:rgb(243, 89, 89);
}

.link {
  padding-top: 5px;
  padding-left: 0px;
  font-size: 15px;
  cursor:pointer;
  color:rgb(119, 149, 247);
}

.link:hover {
  color:rgb(152, 176, 255);
}
