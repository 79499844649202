.container {
  position: absolute;
  display: flex;
  max-width: 200px;
  min-width: 200px;
  height: 200px;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  padding-top: 8px;
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    padding-top: 0px;
    padding-left: 8px;
  }
}

@media print {
  .container {
    display: none;
  }
}
