.container {
  position: relative;
  opacity: 1;
  height: 30px;
  padding: 0px;
  margin: 0px;
  display: block;
  background: #ffffff;
  pointer-events: none;
  overflow: visible;
}

.container:hover {
  font-weight: bold;
  border-bottom: 1px #ccc dotted;
}

.background {
  position: absolute;
  height: 30px;
  width: 100%;
  background: #ffffff;
  pointer-events: auto;
  cursor: pointer;
  border-bottom: 1px #ccc dotted;
}

.background:hover {
  background: rgba(121, 121, 121, 0.247);
  margin-left: 0px;
  width: 100%;
  height: 30px;
  pointer-events: auto;
}

.icon {
  position: relative;
  padding-left: 10px;
  top: 5px;
}

.renameInputContainer {
  position: relative;
  top: -15px;
  left: 17px;
  z-index: 20;
  pointer-events: auto;
}

.renameInput {
  position: absolute;
  left: 15px;
  width: 150px;
  pointer-events: auto;
}

.renameButton {
  position: absolute;
  left: 195px;
  top: 3px;
  width: 40px;
  pointer-events: auto;
}

.title {
  position: absolute;
  top: 4px;
  left: 34px;
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  padding: 2px;
  font-size: 14px;
  text-overflow: ellipsis;
}

.dropdown {
  position: absolute;
  right: 0px;
  padding: 5px;
  padding-bottom: -2;
  pointer-events: auto;
  cursor: auto;
}

.dropdown:hover {
  background-color: #e0ee86;
  pointer-events: auto;
}
