.CreateAssignment {
  position: absolute;
  bottom: 0px;
  left: 3px;
}

.CreateAssignmentMax {
  width: 500px;
  z-index: 20;
}

.CreateAssignmentMin {
  width: 300px;
  z-index: 20;
}

.CreateAssignmentMenu {
  height: 45px;
}

.CreateAssignmentSizeIcon {
  position: absolute;
  right: 30px;
}

.CreateAssignmentCloseIcon {
  position: absolute;
  right: 10px;
}

.CreateAssignmentContentShown {
  height: 100%;
}

.CreateAssignmentContentHidden {
  display: none;
}

.CreateAssignmentTitle {
  position: absolute;
}

.CreateAssignmentShownButton {
  position: absolute;
  top: 135px;
  left: 105px;
}

@media print {
  .CreateAssignment {
    display: none;
  }
}
