.container {
  position: relative;
  padding-top: 5px;
}

.gridRowContainer {
  position: relative;
  left: 2px;
  display: flex;
  justify-content: space-around;

  // workaround to support aligning the grid to svg in the first measure.
  &.first {
    margin-left: 20%;

    &.subdivision {
      margin-left: 11.5%;
    }
  }
}
