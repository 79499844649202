.CreateBeat {
  position: absolute;
  bottom: 0px;
  z-index: 100;
}

.CreateBeatOpen {
  position: absolute;
  top: 135px;
  left: 15px;
}

.CreateStockBeatOpen {
  position: absolute;
  top: 135px;
  left: 150px;
}

.CreateBeatForm {
  display: flex;
  flex-wrap: wrap;
}

.CreateBeatTextArea {
  position: relative;
  flex: 100%;
}

.CreateBeatContentShown {
  height: 100%;
}

.CreateBeatContentHidden {
  display: none;
}

.CreateBeatInput {
  flex: 50%;
  padding-right: 5px;
}

.CreateBeatDropdownContainer {
  flex: 50%;
  padding-left: 5px;
}

.CreateBeatDropdown {
  height: 40px;
}

.CreateBeatButton {
  position: relative;
  height: 44px;
  width: 70px;
  display: flex;
}

.CreateStockBeatButton {
  height: 40px;
  position: relative;
  top: 0px;
  width: 110px;
  font-size: 20px;
}

.CreateBeatErrorMsg {
  position: relative;
  top: -88px;
  left: 120px;
  width: 70%;
  height: 50px;
  z-index: 200;
  pointer-events: none;
}
