.StudentAssignmentsContent {
  background-color: #fff;
  padding: 10px;
  width: 100%;
  position: relative;
  color: #797979;
}

.StudentAssignmentsAssignmentsTextP {
  width: 100%;
}

.StudentAssignmentsBorder {
  position: relative;
  left: 10px;
  border-left: 1px solid rgb(226, 226, 226);
}

.StudentAssignmentsAssignmentsText {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  white-space: pre-wrap;
}
