.showHideIcon {
  position: absolute;
  top: 0px;
  right: 10px;
}

.subHeader {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  margin-top: 30px;
  width: 335px;
  border-bottom: 1px solid rgb(187, 187, 187);
  margin-bottom: 15px;
}
