.container {
  position: relative;
  padding: 10px;
  flex: 50%;
}

.message {
  position: absolute;
  top: 120px;
  left: 205px;
}
