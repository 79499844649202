.container {
  position: absolute;
  bottom: 0px;
}

@media print {
  .container {
    display: none;
  }
}
