.panel {
  position: relative;
  width: fit-content;
  max-width: 50%;
  min-width: 300px;
  border: solid grey 0px;
  background-color: #ffffff;
  opacity: .9;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 5px 6px 2px;
  margin: 0 auto;
  top: 175px;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
