.scrollable {
  position: relative;
}

.titleContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 40px;
  width: 305px;
}

.titleIcon {
  position: absolute;
  top: 11px;
  left: 35px;
}

.titleText {
  position: absolute;
  top: 11px;
  left: 60px;
}

.dropdown {
  position: absolute;
  right: 0px;
  height: 40px;
  padding-bottom: -2;
  top: -15px;
  border-radius: 0px 5px 5px 0px;
  cursor: auto;
}

.dropdown:hover {
  background-color: #e0ee86;
}

.container {
  position: relative;
}

.renameButton {
  position: absolute;
	top: 10px;
  left: 220px;
}

.source {
  position: relative;
}

.dropdownTrigger {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 30px;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

.dropdownTrigger:hover {
  background-color: #e0ee86;
}

.dropdownIcon {
  padding-left: 10px;
  padding-top: 12px;
  pointer-events: none;
}

.input {
  position: absolute;
  left: 55px;
  top: 10px;
}
