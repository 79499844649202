.registerLinkContainer {
  padding: 10px;
  flex: 50%;
}

.copiedLinkMsg {
  display: inline-block;
}

.container {
  display: flex;
  flex-wrap: wrap;
}
