@media print {
  .container,
  .AnimatedGridIsolate,
  .AnimatedGridIsolate * {
    display: none !important;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
