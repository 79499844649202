.dropdown {
  position: relative;
  top: 0px;
  width: 100px;
  margin: 0 auto;
}

.description {
  position: relative;
  width: 600px;
  display: inline-block;
  margin: 0 auto;
}

.current {
  position: relative;
  padding-top: 20px;
  margin: 0 auto;
  width: 200px;
}
