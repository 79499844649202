.titleContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 40px;
  width: 305px;

  :hover {
    cursor: pointer;
  }
}

.titleIcon {
  position: absolute;
  top: 11px;
  left: 35px;
}

.titleText {
  position: absolute;
  top: 11px;
  left: 60px;
}
