.field {
  width: 250px;
}

.error {
  position: relative;
  width: 165px;
  top: -125px;
  left: 85px;
}
