.container {
  position: relative;
  right: 0px; // moving this does not move the popup window. Crazy
  top: 10px;
}

.popupContent {
  position: relative;
  padding: 2px;
  width: auto;
  max-height: 85vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
}

.badge {
  background-color: rgb(243, 66, 66);
  text-align: center;
  color: white;
  border-radius: 10px;
  position: absolute;
  top: -13px;
  left: 10px;
  font-size: 12px;
}

.icon {
  position: relative;
}
