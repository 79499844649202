/* shared styles. putting them here for now. can move when/if we figure out where to put them */

.ui.opaque.compact.button {
  background-color: rgba(255, 255, 255, 0.4);
  color: #000000;
}

/* override user agent stylesheet for button */
button {
  border: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
  text-align: unset;
}

.MailLink {
  position: absolute;
  right: 160px;
  top: 15px;
  color: #ffffff;
}

.IgLink {
  position: absolute;
  right: 120px;
  top: 15px;
  color: #ffffff;
}

.FbLink {
  position: absolute;
  right: 80px;
  top: 15px;
  color: #ffffff;
}

.LoginButton {
  position: absolute;
  top: 19px;
  right: 30px;
  z-index: 20;
}

/* end shared styles */

/* Slider toggle with CSS from https://www.w3schools.com/howto/howto_css_switch.asp */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6b6d6e;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &.disabled:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    background-color: rgb(168, 168, 168);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: #ccc;

  &.disabled {
    background-color: rgb(145, 145, 145);
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #6b6d6e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

input.Blk[type="checkbox"]:checked {
  background: rgba(0, 0, 0, 1);
}

input[type="checkbox"]:checked {
  &.Crash {
    background: rgb(52, 188, 188);
  }
  &.Hi-Hat {
    background: rgba(47, 168, 57, 1);

    &.Pedal {
      background: rgb(0, 16, 155);
    }
  }
  &.Hi-Tom {
    background: rgb(226, 209, 18);
  }
  &.Mid-Tom {
    background: rgb(255, 157, 38);
  }
  &.Snare {
    background: rgba(219, 57, 57, 1);
  }
  &.Lo-Tom {
    background: rgb(135, 91, 223);
  }
  &.Bass {
    background: rgba(43, 121, 229, 1);
  }
  &.Hide {
    background: rgba(175, 175, 175, 1);
  }
}

input.Hide[type="checkbox"]:not(:checked) {
  background: rgba(225, 225, 225, 1);
}

input[type="checkbox"]:focus {
  outline: 0;
}

/* Rounded sliders */
.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}

.ui.push.left.wide.visible.sidebar {
  width: 350px !important;
}

.ui.button.CreateOrLoginButton {
  width: 170px;
  height: 60px;
  background-color: #c0d538;
}

.ReturnToRegistration {
  position: relative;
}

.ResendEmailButton {
  position: absolute;
  top: 148px;
  left: 100px;
}

.EmailResentMessage {
  position: absolute;
  top: 155px;
  left: 240px;
}

@media screen and (max-width: 992px) {
  .row {
    flex-direction: column;
  }
}

.RegisterPanel {
  position: relative;
  width: 550px;
  border: solid grey 0px;
  background-color: #ffffff;
  opacity: 0.9;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 5px 6px 2px;
  margin: 0 auto;
  top: 175px;
  padding: 30px;
}

.ResetPasswordPanel {
  position: relative;
  width: 410px;
  border: solid grey 0px;
  background-color: #ffffff;
  opacity: 0.9;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 5px 6px 2px;
  margin: auto;
  top: 175px;
  padding: 30px;
}

.ResetPasswordFormPanel {
  position: relative;
  width: 410px;
  border: solid grey 0px;
  background-color: #ffffff;
  opacity: 0.9;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 5px 6px 2px;
  margin: auto;
  top: 375px;
  transform: translateY(-50%);
  padding: 30px;
}

.ConfirmContactMessage {
  text-align: center;
  padding: 20px;
  line-height: 1.2em;
}

.drumRoomLogo {
  position: absolute;
  top: 5px;
  left: 5px;
  transform-origin: 0% 0%;
  z-index: 10;
}

.folderDivMask {
  width: 335px;
  padding-right: 0px;
  transition: 0.3s;
}

.MailingList {
  padding-bottom: 70px;
}

.App {
  text-align: center;
  zoom: 2;
}

.noteGrid {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 110px;
}

.noteGrid16 {
  position: relative;
  width: 1400px;
  margin: 0 auto;
  margin-top: 110px;
  left: 70px;
}

.BottomAccentBar {
  position: relative;
  background-color: #c0d538;
  height: 2px;
  width: 100%;
  z-index: 10;
}

.BottomBannerHome {
  position: relative;
  background-color: #000000;
  top: 10px;
  height: 40px;
  width: 100%;
  z-index: 10;
  color: #808080;
}

.BottomBanner {
  position: absolute;
  bottom: 0;
  background-color: #000000;
  height: 10px;
  width: 100%;
  z-index: 10;
  color: #808080;
}

input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
  background: white;
  border: 1px solid #555;
}

input[type="checkbox"]:focus {
  outline: 0;
}

.GridImg {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.GridLabel {
  font-size: 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: left;
  left: -50px;
  position: absolute;
  max-width: 4rem;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

hr,
hr.vertically {
  color: #b2b2b2;
  background-color: #b2b2b2;
}

hr {
  width: 100%;
  height: 0;
}

hr.vertically {
  width: 0;
  height: 100%;
}

/*sets minimum height for the div right before BottomBanner. Banner sticks to bottom as long as window size is larger than this height,
   otherwise it gets cut off on resize. 
*/
.MainWindowContent {
  height: 100vh;
  min-height: 800px;
  background-color: white;
  overflow-x: auto;
}

.BeatLibraryClosableArea {
  position: relative;
}

@page {
  size: letter landscape;
  margin: 0;
}

@media print {
  html,
  body {
    overflow: visible;
    -webkit-print-color-adjust: exact;
    page-break-before: always;
  }

  input[type="checkbox"],
  input[type="radio"] {
    opacity: 1 !important;
  }

  .pushable,
  .pusher,
  .pushable > .pusher,
  .pushable > .pusher:after,
  .MainWindowBody,
  .MainWindowContent {
    overflow: visible;
    display: block;
  }

  footer {
    display: none;
  }

  // need this to print colors in checkboxes. yikes, CSS
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact;
  }

  .MainWindowContent {
    .sheet {
      overflow: hidden;
      width: 90%;
      .measure-wrapper {
        display: flex;
        height: 40vh;
        width: 55%;

        &:nth-child(odd) {
          margin-left: 12%;
          .note-grid-container {
            overflow: visible;
            justify-content: flex-end !important;
            .staff-container {
              overflow: visible !important;
            }
          }
        }

        .note-grid-container {
          > div {
            &:nth-child(4) {
              max-height: 100% !important;
              overflow: visible !important;
            }
          }
        }

        &:first-child {
          margin-left: 1%;
        }

        &:nth-child(n + 2) {
          width: 44%;
        }

        &:nth-child(4n - 1),
        &:nth-child(4n) {
          align-items: flex-start;
        }

        &:nth-child(4n) {
          page-break-after: always;
        }

        &:last-child {
          page-break-after: avoid !important;
          margin-left: 0;
          .note-grid-container {
            overflow: hidden;
          }
        }
        &:only-child {
          width: 100%;
          max-width: 100%;
          height: 80vh;
          .note-grid-container {
            overflow: visible;
            > div {
              max-height: 100% !important;
            }
          }
        }

        svg {
          align-items: center;
          justify-content: center;
          width: 100%;
          height: auto;
          overflow: visible !important;
          .measure-svg {
            overflow: visible !important;
            path {
              transform: scaleX(2) translateX(-50%);
            }
          }
        }
        &:first-child,
        &:nth-child(2),
        &:nth-child(4n + 1),
        &:nth-child(4n + 2) {
          display: flex;
          align-items: center !important;
        }
      }
    }

    &.grid-active {
      .sheet {
        .measure-wrapper {
          .note-grid-container {
            > div {
              &:nth-child(4) {
                max-height: 100% !important;
                overflow: visible !important;
                opacity: 1 !important;
                input {
                  width: 30px !important;
                  height: 30px !important;
                  border-radius: 9px !important;
                }
              }
            }
          }

          &:only-child {
            margin-left: 0;
            width: 100% !important;
            .note-grid-container {
              > div {
                &:nth-child(4) {
                  max-height: 100% !important;
                  overflow: visible !important;
                  opacity: 1 !important;
                  input {
                    width: 60px !important;
                    height: 60px !important;
                    border-radius: 16px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.subdivision-active {
      .sheet {
        .measure-wrapper {
          width: 50% !important;
          &:nth-child(n + 2) {
            width: 44% !important;
          }
          &:first-child {
            margin-left: 6%;
          }
          &:nth-child(2) {
            overflow: hidden !important;
          }
          .note-grid-container {
            > div {
              &:nth-child(4) {
                max-height: 0px !important;
              }
            }
          }
          &:only-child {
            margin-left: 0;
            width: 100% !important;
          }
        }
      }

      &.grid-active {
        .sheet {
          .measure-wrapper {
            .note-grid-container {
              > div {
                &:nth-child(4) {
                  max-height: 100% !important;
                }
              }
            }

            &:only-child {
              .note-grid-container {
                > div {
                  &:nth-child(4) {
                    input {
                      width: 30px !important;
                      height: 30px !important;
                      border-radius: 8px !important;
                    }
                  }
                }
              }
            }
            .note-grid-container {
              > div {
                &:nth-child(4) {
                  input {
                    width: 16px !important;
                    height: 16px !important;
                    border-radius: 4px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
