.container {
  position: absolute;
  right: 0px;
  margin: auto;
  width: 200px;
  height: 40px;
  background-color: rgba($color: #e0e0e0, $alpha: 1);
  border-radius: 0px 0px 0px 10px;
  box-shadow: 0px 4px 8px #888888;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

@media screen and (max-width: 1000px) {
  .container {
    width: 40px;
    height: 200px;
  }
}

.item {
  flex: 15%;
  padding: 3px;
  margin: 3px;
  height: 32px;
  max-width: 40px;
}

.itemActive {
  flex: 15%;
  padding: 3px;
  margin: 3px;
  height: 32px;
  max-width: 40px;
  background-color: rgba(160, 160, 160, 0.541);
  border-radius: 5px;
}

.item:hover,
.itemActive:hover {
  cursor: pointer;
  background-color: rgba(114, 114, 114, 0.541);
  border-radius: 5px;
}

@media print {
  .container {
    display: none;
  }
}
