.container {
  position: relative;
  background-color: rgba($color: #e0e0e0, $alpha: 1);
  width: 500px;
  height: 40px;
  margin: 0 auto;
  border-top: 1px solid #c0d538;
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
  box-shadow: 0px 4px 8px #888888;
  text-align: center;
  @media print {
    box-shadow: none !important;
  }
}

.reloadIcon {
  position: absolute;
  top: 5px;
  right: 0px;
  @media print {
    display: none !important;
  }
}

.undoIcon {
  position: absolute;
  top: 5px;
  right: -40px;
  @media print {
    display: none !important;
  }
}

.redoIcon {
  position: absolute;
  top: 5px;
  right: -75px;
  @media print {
    display: none !important;
  }
}

.infoIcon {
  position: absolute;
  top: 5px;
  left: 5px;
  @media print {
    display: none !important;
  }
}
