.container {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-around;

  &.first {
    margin-left: 20%;

    &.subdivision {
      margin-left: 11.5%;
    }
  }
}

.label {
  display: inline-block;
  width: 30px;
  padding-left: 10px;
  padding-right: 13px;
  user-select: none;
  font-size: 20px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}
