.accordion {
  position: relative;
  padding-right: 10px;
  width: 335px;
}

.folderTitleContainer {
  position: relative;
  top: 0px;
}

.folderTitleIcon {
  position: absolute;
  top: -18px;
  left: 20px;
}

.folderTitleText {
  position: absolute;

  top: -18px;
  left: 45px;
}

.titleContainer {
  position: relative;
}
