.toggle {
  display: flex;
  padding: 2px 5px 2px 5px;
  color: #ffffff;
}

.label {
  padding: 0px 5px 0px 5px;
}

.disabled {
  color: #c9c9c9
}
