.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.box {
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.addMeasureButton {
  @media print {
    display: none;
  }
}
.addMeasureInput {
  max-width: 200px;
  align-items: center;
}
