$activeBackground: rgb(248, 248, 248);
$borderColor: rgb(0, 0, 0);

.measureWrapper {
  background: rgb(255, 255, 255);
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
  &:hover {
    background: $activeBackground;
    border-radius: 10px;
  }
  @media print {
    &:first-child {
      margin-left: -100px;
    }
  }
}

.highlighted {
  background: $activeBackground;
  border-radius: 10px;
  z-index: 1;
  @media print {
    background: transparent;
  }
}

.measureButtons {
  display: flex;
  justify-content: flex-end;
  min-height: 20px;
  padding: 5px 0px;
  @media print {
    display: none;
  }
}

.icon {
  font-size: 14px !important;
  margin-top: -4px !important; // override semantic ui react icon size
}

.noteGridContainer {
  position: relative; // necessary so Playhead's height: 100% works
  @media print {
    width: 100% !important;
  }
}
