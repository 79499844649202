.caption {
  position: relative;
  margin-bottom: 20px;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.firstName {
  flex: 50%;
  padding-right: 5px;
}

.lastName {
  flex: 50%;
  padding-left: 5px;
}
