.text {
  position: relative;
  top: 0px;
  left: 0px;
  color: rgba($color: #ffffff, $alpha: 1);
  display: inline-block;
  padding-right: 25px;
}

.input {
  width: 32px;
  position: absolute;
  display: block;
  top: -1px;
  left: 40px;
  text-align: center;
}

.input::placeholder {
  font-size: x-small;
}

.label {
  position: relative;
  top: -0px;
  left: 0px;
  margin: 0;
  display: inline-block;
  padding-right: 15px;
}

.number {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 25px;
  color: white;
}
