.container {
  bottom: 20px;
  position: absolute;
  right: 10px;
}

.modal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.description {
  flex: 100%;
  padding-bottom: 10px;
}
 
.textArea {
  flex: 100%;
  min-height: 300px;
  max-height: 500px;
  min-width: 100%;
  max-width: 100%;
  padding: 10px;
}

@media print {
  .container {
    display: none;
  }
}
