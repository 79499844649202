.container {
  position: relative;
  height: 58px;
  width: 100%;
  background-color: #808080;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 10px;
}

.button {
  padding: 2px;
}

@media print {
  .container {
    display: none;
  }
}
