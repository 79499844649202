.container {
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 20px;

  // workaround to support aligning the grid to svg in the first measure.
  &.first {
    margin-left: 20%;
    .label {
      width: 10%;
      padding: 0 0;
    }

    &.subdivision {
      margin-left: 11.5%;
      .label {
        width: 5.5%;
      }
    }
  }
  &:not(.first) {
    .label {
      width: 12.5%;
    }
    &.subdivision {
      .label {
        width: 6.25%;
      }
    }
  }
}

.label {
  display: inline-block;
  padding: 0 0 0 2px;
  user-select: none;
  position: relative;
  font-size: 18px;
  text-align: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.title {
  position: absolute;
  float: left;
  font-size: 14px;
}

.label:hover {
  font-weight: bold;
}

.input {
  display: none;
}
