.container {
  padding: 10px 10px 10px 5px;
  border-style: none none solid none;
  border-width: 1px; 
  border-color: rgba($color: #000000, $alpha: .3);
  width: 300px;
}

.container:hover {
  background-color: rgba($color: #000000, $alpha: .2);
}

.date {
  position: relative;
  font-style: italic;
}

.payloadName {
  margin-top: -5px;
  font-weight: bold;
  display: inline-block;
}

.icon {
  display: inline-block;
}
