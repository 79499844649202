.container {
  padding: 0px 20px 0px 20px;
}

.input {
  position: absolute;
  width: 33px;
  z-index: 10;
  text-align: center;
}

.playbackSwingLabel {
  position: relative;
  text-align: center;
  color: white;
  display: inline-block;
  padding-right: 9px;
  margin-bottom: 5px;
}

.playbackSwingNumber {
  position: relative;
  text-align: center;
  color: white;
  display: inline-block;
  padding-right: 15px;
  width: 50px;
}
