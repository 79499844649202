.container {
  position: relative;
  padding-left: 10px;
  height: 100%;
  min-height: 800px;
  padding-top: 10px;
  padding-bottom: 60px;
  border-left: 2px solid #c0d538;
  overflow-x: hidden; //allows transparent scrollbar
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  width: 0.7em;
  background: transparent;
  display: none;
}
.container::-webkit-scrollbar-thumb {
  background: #c0392b;
  height: 30px;
}

.container::-webkit-scrollbar-track-piece {
  display: none;
}

.title {
  color: #ffffff;
  padding-left: 10px;
  padding-top: 10px;
  margin: 0px;
}

.titleBg {
  position: relative;
  height: 45px;
  width: 355px;
  background-color: #000000;
  margin-left: -10px;
  margin-bottom: -12px;
  margin-top: -10px;
}
