.flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.firstName {
  width: 50%;
}

.lastName {
  width: 50%;
}
